import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { firstValueFrom } from 'rxjs';

import { IResultHttpApp } from '../interfaces/IResultHttpApp';
import { SpinnerAppService } from './spinner-app.service';
import { AlertAppService } from './alert-app.service';
import { ControleLoginAppService } from './controle-login-app.service';
import { FuncoesGeraisAppService } from './funcoes-gerais-app.service';
import { RegiaoTuristicaModel } from 'src/models/regiaoTuristicaModel';

@Injectable({
  providedIn: 'root'
})
export class HttpAppService {
  _msgErroPadrao: string = 'Não foi possível acessar o servidor do sistema. Verifique o acesso à internet ou tente mais tarde.'

  _arrayTipeFile = [
    { extensao: 'png', mimeType: 'image/png' },
    { extensao: 'jpg', mimeType: 'image/jpeg' },
    { extensao: 'jpeg', mimeType: 'image/jpeg' },
    { extensao: 'pdf', mimeType: 'application/pdf' },
    { extensao: 'doc', mimeType: 'application/msword' },
    { extensao: 'docx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { extensao: 'xls', mimeType: 'application/vnd.ms-excel' },
    { extensao: 'xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extensao: 'zip', mimeType: 'application/zip' },
    { extensao: 'csv', mimeType: 'text/csv' },
    { extensao: 'txt', mimeType: 'text/plain' }
  ]

  constructor(
    private http: HttpClient,
    private router: Router,

    private alertAppSrv: AlertAppService,
    private spinnerAppSrv: SpinnerAppService,
    private funcoesGeraisAppSrv: FuncoesGeraisAppService,
    private controleLoginAppSrv: ControleLoginAppService,
  ) {

  }

  createHeader(header?: HttpHeaders, flUploadFile?: boolean, nmRegiaoTuristicaSelecionada?: string): HttpHeaders {
    if (!header) {
      header = new HttpHeaders();
    }
    // as chamadas normais envia o header Content-Type, exceto no upload de arquivos
    if (!flUploadFile) {
      header = header.append('Content-Type', 'application/json');
    }
    header = header.append('Accept', 'application/json');
    let token: string = '';
    if (localStorage.getItem('PasseioJaApp:token')) {
      token = localStorage.getItem('PasseioJaApp:token') as string;
    } else {
      token = this.controleLoginAppSrv.ObterTokenUsuario();
    }

    if (token) {
      header = header.append('x-token-access', token);
    }
    // o nmRegiaoTuristicaSelecionada so vai existir na query de passeios da regiao
    // talvez fosse pq em alguns casos ocorria da localstorage ter sido deletada para recriacao,
    // por funcoes executadas em paralelo
    // o normalize e replace abaixo eh para tirar acentuacao no conteudo do header
    if (nmRegiaoTuristicaSelecionada) {
      header = header.append('regiao', nmRegiaoTuristicaSelecionada.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    } else {
      if (localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')) {
        const _regiaoTuristicaSelecionada: RegiaoTuristicaModel = JSON.parse(localStorage.getItem('PasseioJaApp:regiaoTuristicaSelecionada')!);
        header = header.append('regiao', _regiaoTuristicaSelecionada.nmRegiaoTuristica.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      }
    }
    // a partir de 08/09/2023 passou a enviar a localizacao como sendo o cdPais, sgEstado e nmCidade obtida
    // e nao mais a regiao, mesmo porque, estava tratando somente regioes publicadas e os
    // usuarios fora delas não ficavam informacao do local
    if (localStorage.getItem('PasseioJaApp:dsLocalizacao')) {
      header = header.append('localizacao', localStorage.getItem('PasseioJaApp:dsLocalizacao')!.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    }
    // if (localStorage.getItem('PasseioJaApp:regiaoLocalizacao')) {
    //   const _regiaoLocalizacao: RegiaoTuristicaModel = JSON.parse(localStorage.getItem('PasseioJaApp:regiaoLocalizacao'));
    //   header = header.append('localizacao', _regiaoLocalizacao.nmRegiaoTuristica.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    // }
    // header = header.append('version', environment.version);
    header = header.append('version', this.funcoesGeraisAppSrv.GetAppVersion());
    if (localStorage.getItem('PasseioJaApp:device')) {
      header = header.append('device', localStorage.getItem('PasseioJaApp:device')!.normalize("NFD").replace(/[\u0300-\u036f]/g, "") as string);
    }
    return header;
  }

  public get(url: string, flNaoUsarSpinner?: number, msgSpinner?: string, nmRegiaoTuristicaSelecionada?: string): Promise<IResultHttpApp> {
    let header: any;
    if (nmRegiaoTuristicaSelecionada) {
      header = this.createHeader(undefined, undefined, nmRegiaoTuristicaSelecionada);
    } else {
      header = this.createHeader();
    }
    // console.log('GET API - ', url)
    return new Promise(async (resolve) => {
      try {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          await this.spinnerAppSrv.Show(msgSpinner ? msgSpinner : undefined);
        }
        const res = await firstValueFrom(this.http.get(url, { headers: header }));
        if (res) {
          if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
            this.spinnerAppSrv.Hide();
          }
          // console.log('retorno get API ok - ', url)
          // console.log(res)
          resolve({ success: true, data: res, error: null });
        }
      } catch (error: any) {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinnerAppSrv.Hide();
        }
        // console.log('retorno get API ERRO - ', url)
        // console.log(error.status);
        // console.log(error.error);
        if (error.status == 400) {
          // se não deu token expirado retorna o erro,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            resolve({ success: false, data: {}, error });
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  public post(url: string, model: any, flNaoUsarSpinner?: number, headers?: HttpHeaders, msgSpinner?: string): Promise<IResultHttpApp> {
    const header = this.createHeader(headers);
    // console.log('POST API - ', url)
    return new Promise(async (resolve) => {
      try {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          await this.spinnerAppSrv.Show(msgSpinner ? msgSpinner : 'Salvando...');
        }
        const res = await firstValueFrom(this.http.post(url, model, { headers: header }));
        if (res) {
          if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
            this.spinnerAppSrv.Hide();
          }
          // console.log('retorno post API ok - ', url)
          resolve({ success: true, data: res, error: null });
        }
      } catch (error: any) {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinnerAppSrv.Hide();
        }
        // console.log('retorno post API ERRO - ', url)
        if (error.status === 400) {
          // se não deu token expirado apresenta os erros,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            // situaçoes especiais a primeira msg vem iniciando com '999999' para tratamento especial no APP
            // e não mostra erros aqui
            // por exemplo: vide retorno da confirmação de pedido
            if (Array.isArray(error.error)
              && error.error[0].message.substring(0, 6) == '999999') {
              resolve({ success: false, data: {}, error });
            } else {
              this.alertAppSrv.MostrarErros(error);
              resolve({ success: false, data: {}, error });
            }
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          this.alertAppSrv.MostrarErros(error);
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  // Rota especial para upload de arquivo diferente de imagem
  // cujo model vai no formato FormaData (com enctype próprio para envio de file)
  public uploadFile(url: string, model: any, flNaoUsarSpinner?: number, headers?: HttpHeaders, msgSpinner?: string): Promise<IResultHttpApp> {
    const _flUploadFile = true;
    // flag _flUploadFile serve para não enviar no header o Content-Type', 'application/json
    const header = this.createHeader(headers, _flUploadFile);
    return new Promise(async (resolve) => {
      try {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          await this.spinnerAppSrv.Show(msgSpinner ? msgSpinner : 'Enviando arquivo...');
        }
        const res = await firstValueFrom(this.http.post(url, model, { headers: header }));
        if (res) {
          if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
            this.spinnerAppSrv.Hide();
          }
          resolve({ success: true, data: res, error: null });
        }
      } catch (error: any) {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinnerAppSrv.Hide();
        }
        if (error.status === 400) {
          // se não deu token expirado apresenta os erros,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            // situaçoes especiais a primeira msg vem iniciando com '999999' para tratamento especial no APP
            // e não mostra erros aqui
            // por exemplo: vide retorno da confirmação de pedido
            if (Array.isArray(error.error)
              && error.error[0].message.substring(0, 6) == '999999') {
              resolve({ success: false, data: {}, error });
            } else {
              this.alertAppSrv.MostrarErros(error);
              resolve({ success: false, data: {}, error });
            }
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          this.alertAppSrv.MostrarErros(error);
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }


  public delete(url: string): Promise<IResultHttpApp> {
    const header = this.createHeader();
    return new Promise(async (resolve) => {
      try {
        await this.spinnerAppSrv.Show('Atualizando...');
        const res = await firstValueFrom(this.http.delete(url, { headers: header }));
        if (res) {
          this.spinnerAppSrv.Hide();
          resolve({ success: true, data: res, error: null });
        }
      } catch (error: any) {
        this.spinnerAppSrv.Hide();
        // console.log(error);
        if (error.status === 400) {
          // se não deu token expirado retorna o erro,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            resolve({ success: false, data: {}, error });
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  // caso retorne token expirado da API, direciona para o login
  async tokenExpirado(error: any): Promise<string> {
    let _textoMsg: string = '';
    if (Array.isArray(error.error) && error.error[0]) {
      _textoMsg = error.error[0].message;
    } else {
      _textoMsg = error.message;
    }
    if (_textoMsg == 'Token expirado') {
      let _retirouLogin = this.controleLoginAppSrv.Logout();
      if (_retirouLogin) {
        // se estiver na pagina principal, ou seja, está retornando a acessar o site após vencer o login
        // vai só retirar o login e tentar acessar novamente (sem usar o token)
        if (this.router.url.includes('tabs/passeios')
          && !this.router.url.includes('reserva')) {
          // console.log('estava com login expirado')
          window.location.reload();
          // this.router.navigateByUrl('');
          return 'S';
        } else {
          this.alertAppSrv.toast('Necessário novo login');
          setTimeout(() => { this.router.navigateByUrl('/tabs/login'); }, 100, []);
          return 'S';
        }
      } else {
        return 'N';
      }
    } else {
      // console.log('vai voltar tokenExpirado false')
      return 'N';
    }
  }

  // usada quando erro.status diferente de 400
  substituirPorErroPadrao(error: any) {
    if (Array.isArray(error.error)) {
      let _itemErro: any;
      if (error.length == 1) {
        _itemErro = error[0];
        _itemErro.message = 'Ocorreu um erro inesperado ao acessar o servidor.' +
          ' Favor verificar sua internet e tentar novamente'
        error.splice(0, 1, _itemErro);
      } else {
        _itemErro = error[0];
        _itemErro.message = 'Ocorreu um erro inesperado ao acessar o servidor.'
        error.splice(0, 1, _itemErro);
        _itemErro = error[1];
        _itemErro.message = 'Por favor, verifique sua internet e tente novamente.'
        error.splice(1, 1, _itemErro);
      }
    } else {
      // o retorno da API não vem como array quando ocorre um erro diferente
      // de status 400 (retornado pela aplicação)
      console.log('Erro no tratamento do erro padrão - ', error.message)
      error.message = 'Ocorreu um erro inesperado ao acessar o servidor.' +
        ' Por favor, verifique sua internet e tente novamente.'
    }
    return error;
  }

  // usado para abrir arquivos a partir de uma URL quando rodando em plataform = ios ou android
  // (pdf de boleto por exemplo e outros arquivos armazenados no servidor)
  // abrindo usando recurso do google.docs para visualização de PDF /docx /xlsx /txt
  // Para imagens usa abrir direto a imagem sem o uso do visualizador google
  // (atualmente não esta usando esta rotina para imagens - usando o componente VisualizadorFotoModalAppPage)
  //   ==> ios À TESTAR -
  //       tentar com o docs.google (idem Android)
  //       caso dê problema, tentar retornar o uso do viewDocument (cordova) e se não der certo,
  //       e caso persistir o problema, estudar se previewAnyFile do ionic funcionaria
  async visualizarFileURLDevice(url: string, nmArquivo?: string, extensao?: string) {
    try {
      let _extensao: string = 'pdf';
      if (extensao) {
        _extensao = extensao;
      }
      let _mimeType: string = 'application/pdf';
      for (var i = 0; i < this._arrayTipeFile.length; i++) {
        if (_extensao == this._arrayTipeFile[i].extensao) {
          _mimeType = this._arrayTipeFile[i].mimeType;
          break;
        }
      }
      // SOLUCAO PARA iOS FICOU IGUAL AO ANDROID - Caso problema tentar retornar na versão 0.0.55
      if (_mimeType.substring(0, 5) == 'image') {
        // a principio essa opção não está sendo usada pois usa o VisualizadorFotoModalAppPage
        // obs.: o docs.google nao funciona para imagens
        let opts: string = "location=yes,clearcache=yes,hidespinner=no,fullscreen=yes";
        // this.iab.create(url, '_system', opts);
        await Browser.open({ url: url });
      } else {    // pdf, txt, docx, xlsx - abre usando visualizador google
        let opts: string = "location=yes,clearcache=yes,hidespinner=no,fullscreen=yes";
        // this.iab.create(`https://docs.google.com/gview?embedded=true&url=${url}`, '_system', opts);
        await Browser.open({ url: `https://docs.google.com/gview?embedded=true&url=${url}` });
      }
      return 'ok';
    } catch (error: any) {
      this.alertAppSrv.alert('Atenção', 'Não foi possível gerar o PDF', error);
      // console.log('Erro na criação PDF', error)
      return 'Erro';
    }
  }

}

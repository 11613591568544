import { LoadingController } from '@ionic/angular/standalone';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerAppService {

  public spinner: HTMLIonLoadingElement | undefined;
  private flAndamento = false;

  constructor(private loadingCtrl: LoadingController) {
  }

  async Show(message?: string): Promise<void> {
    // console.log('spinnerAppSrv - entrou no show')
    try {
      if (!this.spinner) {
        this.flAndamento = true;
        this.spinner = await this.loadingCtrl
          .create({ message: (message && message != '' ? message : 'Carregando...') });
        if (this.spinner) {
          // console.log('spinnerAppSrv - criou')
          this.spinner.present()
            .then(() => {
              // console.log('spinnerAppSrv - mostrou?')
              this.flAndamento = false;
            })
            .catch(error => {
              // console.log('spinnerAppSrv - deu erro?')
              this.flAndamento = false;
            });
        }
        // } else {
        //   console.log(this.spinner)
      }
    } catch (error) {
      console.log('erro no spinner')
      return;
    }
  }

  Hide(): void {
    // console.log('spinnerAppSrv - entrou no hide')
    const interval = setInterval(() => {
      try {
        if (this.spinner || !this.flAndamento) {
          this.spinner!.dismiss()
            .then(() => {
              // this.spinner = {} as HTMLIonLoadingElement;
              this.spinner = undefined;
              clearInterval(interval);
            });
        } else {
          if (!this.spinner && !this.flAndamento) {
            clearInterval(interval);
          }
        }
      } catch (error) {
        // this.spinner = {} as HTMLIonLoadingElement;
        this.spinner = undefined;
        clearInterval(interval);
      }
    }, 500);
  }

}
